import React from 'react';

interface IIconProps {
  className?: string;
}

const Icon = (props: IIconProps) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#4468FF"
          strokeWidth="3"
          d="M27.946 24.548v9M19.217 14.548v19M11 22.548v11M36.163 9.548v24"
        />
      </g>
    </svg>
  );
};

export default React.memo(Icon);
