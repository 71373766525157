import React, { useState } from 'react';

import BigBanner from '../components/Banners/BigBanner';
import { PrimaryButton } from '../components/Buttons/PrimaryButton';
import { SecondaryButton } from '../components/Buttons/SecondaryButton';
import { Offers } from '../components/Carousels/Offers';
import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import { ContentSection } from '../components/ContentSection/ContentSection';
import { DedicatedSupport } from '../components/DedicatedSupport/DedicatedSupport';
import EarnMore from '../components/EarnMore/EarnMore';
import JoinForm from '../components/Forms/Join';
import Modal from '../components/Modal/index';
import LEIcon1 from '../images/Icons/Icon-1';
import LEIcon2 from '../images/Icons/Icon-2';
import LEIcon3 from '../images/Icons/Icon-3';
import LEIcon4 from '../images/Icons/Icon-4';
import heroBGImage from '../images/index/background.jpg';
import codeMobileImage from '../images/index/code-mobile.png';
import codeImage from '../images/index/code.png';
import dashboardMobileImage from '../images/index/dashboard-mobile.png';
import dashboardImage from '../images/index/dashboard.png';
import offersImage from '../images/index/offers.png';

import style from '../styles/pages/index.scss';

import cn from 'classnames';
import { graphql } from 'gatsby';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

interface IIndexProps {
  data: {
    allGhostPost: any;
  };
  location: {
    pathname: string;
  };
}

const Index = ({ data, location }: IIndexProps) => {
  const [isJoinModalShown, setJoinModalShown] = useState(false);
  const [trafficType, setTrafficType] = useState('');

  // Join Modal Toggle
  const showJoinModalWithStepSell = () => {
    setJoinModalShown(true);
    setTrafficType('sell_leads');
  };

  const showJoinModalWithStepBuy = () => {
    setJoinModalShown(true);
    setTrafficType('buy_leads');
  };

  const hideJoinModal = () => {
    setJoinModalShown(false);
    setTrafficType('');
  };

  return (
    <>
      <MetaData location={location} data={{}} />
      <Layout isHome>
        <section className={style.hero}>
          <div className="container">
            <div className="d-flex flex-wrap">
              <div className="col-12 col-md-3 col-lg-5 col-xl-6 order-md-last">
                <img src={heroBGImage} alt="Background Lady" />
              </div>
              <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                <div className={style.headerTextBox}>
                  <h1>
                    A partnership with results.
                    <small>
                      A direct Lead Gen network with vast connections in loan and insurance
                      verticals - powered by world class tech.
                    </small>
                  </h1>
                  <PrimaryButton onClick={showJoinModalWithStepSell}>Sell Leads</PrimaryButton>
                  <SecondaryButton onClick={showJoinModalWithStepBuy}>Buy Leads</SecondaryButton>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={style.numbers}>
          <div className="container">
            <div className="d-flex flex-wrap">
              <div className="col-12 col-sm-4">
                <h3>$250+</h3>
                <h5>Top Lead Price</h5>
              </div>
              <div className="col-12 col-sm-4">
                <h3>350+</h3>
                <h5>Active Offers</h5>
              </div>
              <div className="col-12 col-sm-4">
                <h3>100+</h3>
                <h5>Lenders</h5>
              </div>
            </div>
          </div>
        </section>

        <section className={style.partner}>
          <div className="container">
            <div className="d-flex flex-wrap">
              <div className="col-12">
                <h2>
                  Our mission <br />
                  is your success.
                </h2>
              </div>
              <ContentSection
                className={cn('col-12 col-sm-6 col-lg-12', style.partnerCol)}
                inViewAnimationClass={style.isInView}
              >
                <div>
                  <LEIcon1 />
                  <h5>Superior Technology</h5>
                  <p>
                    Our custom, in-house platform delivers faster processing times and deep
                    analytics, which means optimized revenue for your bottom line.
                  </p>
                </div>
              </ContentSection>
              <ContentSection
                className={cn('col-12 col-sm-6 col-lg-12', style.partnerCol)}
                inViewAnimationClass={style.isInView}
              >
                <div>
                  <LEIcon2 />
                  <h5>$250+ Lead Prices</h5>
                  <p>
                    We put your leads in front of our direct network of buyers. With no middleman,
                    we maximize your profits.
                  </p>
                </div>
              </ContentSection>
            </div>
          </div>
          <img
            className={cn('d-none d-sm-block', style.codeImage)}
            src={codeImage}
            alt="Lead Economy"
          />
          <img
            className={cn('d-block d-sm-none', style.codeImage)}
            src={codeMobileImage}
            alt="Lead Economy"
          />
          <img
            className={cn('d-none d-sm-block', style.dashboardImage)}
            src={dashboardImage}
            alt="Lead Economy"
          />
          <img
            className={cn('d-block d-sm-none', style.dashboardImage)}
            src={dashboardMobileImage}
            alt="Lead Economy"
          />
        </section>

        <section className={style.exclusiveOffers}>
          <div className="container">
            <div className="d-flex flex-wrap">
              <div className="col-12">
                <h2 className={style.title}>
                  Boost conversion
                  <br className="d-none d-xl-block" /> rates by an average of 29%
                </h2>
              </div>
              <div className="col-12 col-lg-6 col-xl-8">
                <ContentSection
                  className={cn('col-12', style.col)}
                  inViewAnimationClass={style.isInView}
                >
                  <div>
                    <LEIcon3 />
                    <h5>Quality Control</h5>
                    <p>
                      All offer sites undergo rigorous testing and optimization resulting in
                      dramatically higher conversions than average industry standards.
                    </p>
                  </div>
                </ContentSection>
                <ContentSection
                  className={cn('col-12', style.col)}
                  inViewAnimationClass={style.isInView}
                >
                  <div>
                    <LEIcon4 />
                    <h5>Exclusive Sites &amp; Brands</h5>
                    <p>
                      Get a dedicated site and work with our account managers to customize it around
                      your personal brand to improve performance.
                    </p>
                  </div>
                </ContentSection>
              </div>
              <div className="col-12 col-lg-6 col-xl-4">
                <ParallaxProvider>
                  <Parallax y={[-6, 6]}>
                    <img className={style.offersImage} src={offersImage} alt="Offers Image" />
                  </Parallax>
                </ParallaxProvider>
              </div>
            </div>
            <Offers className="d-sm-none" isTextShown={false} />
          </div>
        </section>

        <DedicatedSupport />

        <EarnMore />

        <Modal
          className={style.joinModal}
          isShown={isJoinModalShown}
          isCloseButtonShown
          onClose={hideJoinModal}
        >
          <JoinForm trafficType={trafficType} />
        </Modal>

        <BigBanner />
      </Layout>
    </>
  );
};

export default Index;

export const pageQuery = graphql`
  query($skip: Int!) {
    allGhostPost(sort: { order: DESC, fields: [published_at] }, limit: 24, skip: $skip) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`;
